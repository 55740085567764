@font-face {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 700;
    font-display: fallback;
    src: local("Montserrat Bold"), local("Montserrat-Bold"), url('https://fonts.gstatic.com/s/montserrat/v12/IQHow_FEYlDC4Gzy_m8fcrllaL-ufMOTUcv7jfgmuJg.woff2') format("woff2");
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F
}

@font-face {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 700;
    font-display: fallback;
    src: local("Montserrat Bold"), local("Montserrat-Bold"), url('https://fonts.gstatic.com/s/montserrat/v12/IQHow_FEYlDC4Gzy_m8fcpsnFT_2ovhuEig4Dh-CBQw.woff2') format("woff2");
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116
}

@font-face {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 700;
    font-display: fallback;
    src: local("Montserrat Bold"), local("Montserrat-Bold"), url('https://fonts.gstatic.com/s/montserrat/v12/IQHow_FEYlDC4Gzy_m8fcnv4bDVR720piddN5sbmjzs.woff2') format("woff2");
    unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB
}

@font-face {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 700;
    font-display: fallback;
    src: local("Montserrat Bold"), local("Montserrat-Bold"), url('https://fonts.gstatic.com/s/montserrat/v12/IQHow_FEYlDC4Gzy_m8fcjrEaqfC9P2pvLXik1Kbr9s.woff2') format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF
}

@font-face {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 700;
    font-display: fallback;
    src: local("Montserrat Bold"), local("Montserrat-Bold"), url('https://fonts.gstatic.com/s/montserrat/v12/IQHow_FEYlDC4Gzy_m8fcmaVI6zN22yiurzcBKxPjFE.woff2') format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2212, U+2215
}

.screen {
    position: relative;
    display: block;
    z-index: 10;
    box-shadow: 8px 8px 0 rgba(0, 0, 0, .3);
    overflow: hidden;
    border-radius: 5px;
}

.screen .screen-bar h2,
.screen .screen-bar h3 {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    text-align: center;
    font-size: 85%;
    text-transform: uppercase;
    font-family: MontSerrat, Tahoma, Arial, sans-serif;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: .5px;
    color: #8a8a8a;
}

@media (max-width: 700px) {

    .screen .screen-bar h2,
    .screen .screen-bar h3 {
        display: none;
    }
}

.screen .screen-bar {
    display: block;
    position: relative;
    height: 30px;
    background-color: #ddd;
    width: 100%;
    color: #6a6a6a;
    z-index: 10
}

.screen .screen-bar i,
.screen .screen-bar i:after,
.screen .screen-bar i:before {
    height: 14px;
    width: 14px;
    display: inline-block;
    background-color: #ff5f57;
    border-radius: 50%;
    position: absolute;
    top: 8px;
    left: 10px
}

.screen .screen-bar i:before {
    content: " ";
    left: 20px;
    background-color: #ffbd2e;
    top: 0
}

.screen .screen-bar i:after {
    content: " ";
    left: 40px;
    background-color: #28ca41;
    top: 0
}

.screen .screen-content .CodeMirror {
    border-radius: 0;
    height: auto;
    max-height: 20%;
}

/*
.screen .screen-content .CodeMirror-scroll {
    overflow: visible !important;
}*/