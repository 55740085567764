.fs-3 {
  font-size: 1.75rem !important;
}

a {
  text-decoration: none !important;
}

.projectTiles {
  --button-primary-color: #2c2c2c !important;
  --button-primary-color-dark: #181818 !important;
  --button-primary-color-light: #d4d9e4 !important;
  --button-primary-color-hover: #202020 !important;
  --button-raise-level: 6px !important;
  height: 100% !important;
  text-decoration: none !important;
}

#loadMore .aws-btn {
  --button-primary-color: #cc0000 !important;
  --button-primary-color-dark: #860000 !important;
  --button-primary-color-light: #ffffff !important;
  --button-primary-color-hover: #af0000 !important;
  --button-primary-color-active: #880000 !important;
  --button-raise-level: 6px !important;
}

#loadMore {
  width: fit-content;
}

#loadMore .aws-btn__content {
  padding: 0 3em !important;
}

.projectTiles .aws-btn__content>span {
  width: 100% !important;
  height: 100% !important;
}

.title {
  font-size: 3rem;
  font-weight: 300;
  line-height: 1.2;
}

.wrapper {
  max-width: 1280px;
  margin: 0 auto;
}

.section {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
}

.heroIcon {
  height: 10rem;
}

@media (max-width: 601px) {
  .heroIcon {
    display: none;
  }
}